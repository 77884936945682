import {Directive, EventEmitter, HostBinding, HostListener, Input, Output} from '@angular/core';

@Directive({
  selector: '[appFileDragDrop]',
  standalone: true
})
export class DragAndDropDirective {
  @Output() private filesChangeEmiter: EventEmitter<File[]> = new EventEmitter();
  @HostBinding('style.background') private background = 'transparent';
  @HostBinding('style.border') private borderStyle = '2px dashed';
  @HostBinding('style.border-color') private borderColor = '#A7A9B4';
  @HostBinding('style.border-radius') private borderRadius = '5px';

  @Input() acceptedFormats: string[] = [];

  @HostListener('dragover', ['$event'])
  public onDragOver(evt: DragEvent) {
    evt.preventDefault();
    evt.stopPropagation();
    this.borderColor = '#A7A9B4';
    this.borderStyle = '3px solid';
  }

  @HostListener('dragleave', ['$event'])
  public onDragLeave(evt: DragEvent) {
    evt.preventDefault();
    evt.stopPropagation();
    this.borderColor = '#A7A9B4';
    this.borderStyle = '2px dashed';
  }

  @HostListener('drop', ['$event'])
  public onDrop(evt: DragEvent) {
    evt.preventDefault();
    evt.stopPropagation();
    this.borderColor = '#A7A9B4';
    this.borderStyle = '2px dashed';
    const files = evt.dataTransfer ? Array.from(evt.dataTransfer.files) : [];
    this.filesChangeEmiter.emit(files);
  }
}
