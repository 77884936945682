@if (uploadedDocuments.length > 0) {
  <h4 class="msa-text-field-set tw-mb-9 tw-mt-30 d:tw-mb-18" [translate]="titleText" [style.color]="titleColor"></h4>

  @for (document of uploadedDocuments; track document.id) {
    <div class="">
      <hr class="tw-text-white" />
      <div
        class="tw-flex tw-min-h-[42px] tw-w-full tw-items-center tw-justify-between tw-gap-6 tw-text-navy-light-2 d:tw-min-h-60"
      >
        <button class="tw-flex tw-items-center" (click)="downloadDocument(document)" data-cy="downloadDocument">
          <div class="tw-me-6 tw-flex-grow-0">
            <mat-icon class="tw-font-large">picture_as_pdf</mat-icon>
          </div>
          <p class="tw-flex-grow-1 tw-content-start tw-break-words tw-text-start">{{ document.name }}</p>
        </button>

        @if (enableDelete) {
          <button
            class="tw-flex-grow-0 tw-pb-18 tw-pe-12 tw-ps-12 tw-pt-18 d:tw-pe-18 d:tw-ps-18"
            (click)="delete(document.id)"
            data-cy="deleteDocument"
          >
            <mat-icon class="tw-font-large">delete</mat-icon>
          </button>
        }
      </div>
    </div>
  }
  <hr class="tw-text-white" />
}
