import {ChangeDetectionStrategy, Component, EventEmitter, input, Output} from '@angular/core';
import {MatIconModule} from '@angular/material/icon';

@Component({
  selector: 'msa-progressbar',
  standalone: true,
  imports: [MatIconModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './msa-progressbar.component.html'
})
export class MsaProgressBarComponent {
  public canCancel = input(true);
  public progress = input.required<number>();

  @Output() cancel = new EventEmitter<void>();
}
