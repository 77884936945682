<div class="tw-flex tw-flex-col-reverse tw-justify-between tw-gap-15 d:tw-flex-row d:tw-gap-30">
  <!--  make sure to keep spacing-->
  <div class="tw-w-full">
    @if (showBackButton()) {
      <msa-button
        class="tw-w-full"
        [disabled]="preventBack"
        (click)="previous.emit()"
        data-cy="previousButton"
        color="secondary"
      >
        {{ "i18n.common.back" | safeTranslate }}
      </msa-button>
    }
  </div>

  <msa-button class="tw-w-full" [disabled]="preventNext" (click)="next.emit()" data-cy="nextButton" color="primary">
    {{ nextButtonText | translate }}
  </msa-button>
</div>
