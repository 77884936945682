/**
 * Format bytes as human-readable text.
 *
 * @param bytes Number of bytes.
 * @param si True to use metric (SI) units, aka powers of 1000. False to use
 *           binary (IEC), aka powers of 1024.
 * @param dp Number of decimal places to display.
 *
 * @return Formatted string.
 */
export function humanReadableByteSize(bytes: number, dp = 1) {
  if (bytes == 0) return '0 Bytes';
  const k = 1024,
    dm = dp || 2,
    sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
    i = Math.floor(Math.log(bytes) / Math.log(k));
  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
}

export const SIZE_1_KB = 1024;
export const SIZE_1_MB = 1024 * SIZE_1_KB;
export const SIZE_1_GB = 1024 * SIZE_1_MB;
