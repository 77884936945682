import {CommonModule} from '@angular/common';
import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';
import {MatButtonModule} from '@angular/material/button';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatIconModule} from '@angular/material/icon';
import {TranslateModule} from '@ngx-translate/core';
import {DocumentInfoDto} from 'projects/admin-query/src/app/core/api/generated/msa-duty-service';

@Component({
  selector: 'msa-file-overview',
  templateUrl: './msa-file-overview.component.html',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule, TranslateModule, MatIconModule, MatButtonModule, MatFormFieldModule]
})
export class MsaFileOverviewComponent<T extends DocumentInfoDto> {
  @Input() titleText = 'i18n.upload-container.dropzone.fileToUpload';
  @Input() titleColor = "theme('colors.white')";
  @Input() uploadedDocuments: T[] = [];
  @Input() enableDelete: boolean = false;
  @Output() remove = new EventEmitter<string>();
  @Output() download = new EventEmitter<T>();

  delete(documentId: string) {
    if (!this.enableDelete) {
      return;
    }
    this.remove.emit(documentId);
  }

  downloadDocument(document: T) {
    this.download.emit(document);
  }
}
