import {CommonModule} from '@angular/common';
import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output, input} from '@angular/core';
import {TranslateModule} from '@ngx-translate/core';
import {SafeTranslateDirective} from '@shared/shared-module/directives/safe-translate.directive';
import {SafeTranslatePipe} from '../../pipes/safe-translate.pipe';
import {MsaButtonComponent} from '../msa-button/msa-button.component';

@Component({
  selector: 'app-form-stepper-navigation',
  templateUrl: './form-stepper-navigation.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, TranslateModule, SafeTranslateDirective, MsaButtonComponent, SafeTranslatePipe]
})
export class FormStepperNavigationComponent {
  public showBackButton = input(true);
  @Input() preventNext = false;
  @Input() preventBack = false;
  @Input() nextButtonText = 'i18n.common.next';

  @Output() next = new EventEmitter();
  @Output() previous = new EventEmitter();
}
