<div class="tw-bg-black-light-1 tw-px-[40px] tw-py-15">
  <div class="tw-flex tw-w-full tw-items-center">
    <div class="tw-flex tw-h-[8px] tw-w-full tw-border-r-0 tw-bg-black-light-3">
      <div
        class="tw-bg-olive-light-1"
        [attr.aria-valuenow]="progress()"
        [style.width.%]="progress()"
        role="progressbar"
        aria-valuemin="0"
        aria-valuemax="100"
      ></div>
    </div>

    <button class="ms-5 tw-flex" (click)="this.cancel.emit()" data-cy="cancelUpload" mat-icon-button>
      <mat-icon>close</mat-icon>
    </button>
  </div>
</div>
